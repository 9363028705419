import styled from "styled-components";
import pin from "./assets/pin.png";
import pin2 from "./assets/pin2.png";
import title from "./assets/title.png";
import { useNavigate } from "react-router-dom";

const Main = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Wrapper>
        <Pin src={pin} alt="pin" />
        <Hr />
        <TitleImg src={title} alt="title" />
        <Container2>
          <Pin2 src={pin2} alt="pin2" />
          <Box>
            <Text>
              몇 가지의 설문조사를 통해, 위암의 5년 예측 사망률을 알아보세요.
            </Text>
            <Btn onClick={() => navigate("/calculator")}>시작하기</Btn>
          </Box>
        </Container2>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #f2fafa;
  display: flex;
  align-items: center;
`;

const Pin = styled.img`
  width: 80px;
  position: absolute;
  right: 10px;
  top: 10px;
`;

const Pin2 = styled.img`
  width: 500px;
  max-width: 500px;
`;

const TitleImg = styled.img`
  width: 450px;
  margin-top: 30px;
  max-width: 450px;
`;

const Hr = styled.hr`
  width: 98%;
  margin: 40px auto 0 auto;
  border: 1px solid rgba(150, 225, 225, 1);
`;

const Wrapper = styled.div`
  background-color: white;
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1000px;
  max-width: 1000px;
  height: 700px;
  max-height: 700px;
  box-shadow: 20px 19px 0px 3px rgba(150, 225, 225, 1);
  -webkit-box-shadow: 20px 19px 0px 3px rgba(150, 225, 225, 1);
  -moz-box-shadow: 20px 19px 0px 3px rgba(150, 225, 225, 1);
  border-radius: 20px;
  border: 3px solid rgba(150, 225, 225, 1);
`;

const Container2 = styled.div`
  margin-top: 40px;
  display: flex;
`;

const Box = styled.div`
  width: 250px;
  border: 4px solid rgba(150, 225, 225, 1);
  margin-left: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  padding: 30px;
`;

const Text = styled.p`
  font-size: 18px;
  line-height: 130%;
  font-weight: bold;
`;

const Btn = styled.div`
  background-color: #4cbeb3;
  &:hover {
    background-color: #3a998e;
  }
  border: none;
  color: white;
  font-size: 20px;
  width: 150px;
  height: 50px;
  border-radius: 10px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  margin-top: 150px;
`;
export default Main;
