import styled from "styled-components";

export const Container = styled.div`
  background-color: #f2fafa;
  padding-top: 150px;
  padding-bottom: 170px;
  @media screen and (max-width: 1279px) {
    padding-top: 170px;
    padding-bottom: 100px;
  }
`;

export const PredictTitle = styled.div`
  font-size: 60px;
  position: absolute;
  top: 60px;
  color: #505050;
  font-weight: bold;
  @media screen and (max-width: 1279px) {
    font-size: 40px;
    top: 100px;
  }
`;

export const SubTitleContainer = styled.div`
  padding-top: 80px;
`;

export const Hr = styled.hr`
  max-width: 350px;
  border: 0.8 solid #505050;
`;

export const SubTitle = styled.div`
  font-size: 27px;
  font-weight: bold;
  text-align: center;
  @media screen and (max-width: 1279px) {
    font-size: 23px;
  }
`;

export const PredictTitleP = styled.p`
  margin-bottom: 10px;
`;

export const DeathRate = styled.span`
  color: #39706b;
`;

export const PredictContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  background-color: white;
  box-shadow: 0px 3px 11px 0px rgba(0, 0, 0, 0.25);
  padding: 0 40px;
  color: #505050;
`;

export const PredictWrapper = styled.div`
  padding: 30px 50px;
  @media screen and (max-width: 1279px) {
    padding: 20px 0px;
  }
`;

export const FieldContainer = styled.div`
  margin: 30px 0;
  @media screen and (max-width: 1279px) {
    margin: 20px 0;
  }
`;

export const Title = styled.label`
  display: block;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  @media screen and (max-width: 1279px) {
    font-size: 18px;
  }
  @media screen and (max-width: 1279px) {
    font-size: 16px;
  }
`;

export const RadioList = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: 640px) {
    display: block;
  }
`;

export const RadioInputContainer = styled.div``;

export const RadioInput = styled.input`
  margin-right: 10px;
  @media screen and (max-width: 1279px) {
    margin-right: 5px;
  }
`;

export const RadioLabel = styled.label`
  margin-right: 30px;
  cursor: pointer;
  font-size: 17px;
  color: #6b6b6b;
  font-weight: bold;
  vertical-align: 20%;
  @media screen and (max-width: 1279px) {
    margin-right: 10px;
    font-size: 15px;
  }
`;

export const List = styled.div`
  padding: 10px 20px;
  font-size: 17px;
  margin-right: 20px;
  border: 2px solid #4cbeb3;
  border-radius: 8px;
  background-color: #f2fafa;
  cursor: pointer;
`;

export const Input = styled.input`
  width: 200px;
  height: 30px;
  padding: 5px;
  color: #505050;
  border: 2px solid #b1b1b1;
  border-radius: 5px;
  outline: none;
  font-size: 16px;
  font-weight: bold;
  outline: none;
  &:focus {
    border: 2px solid #4cbeb3;
  }
  @media screen and (max-width: 1279px) {
    width: 200px;
    height: 25px;
  }
`;

export const Error = styled.div`
  display: flex;
  margin-top: 10px;
`;

export const ErrorMsg = styled.p`
  color: #e93c59;
  font-weight: bold;
  margin-top: 3px;
  margin-left: 5px;
  @media screen and (max-width: 1279px) {
    font-size: 14px;
  }
`;

export const BtnContainer = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
`;

export const ResultBtn = styled.button`
  padding: 10px 80px;
  font-size: 30px;
  border-radius: 20px;
  border: none;
  margin-top: 40px;
  background-color: #4cbeb3;
  color: white;
  cursor: pointer;
  &:hover {
    background-color: #3a998e;
  }
  @media screen and (max-width: 1279px) {
    padding: 5px 40px;
    font-size: 22px;
    border-radius: 13px;
  }
`;

export const Result = styled.div`
  font-size: 25px;
  margin: 40px 0;
  text-align: center;
  font-weight: bold;
  @media screen and (max-width: 1279px) {
    font-size: px;
  }
`;
