import api from "../axios/LocationApi";

// 암 예후예측

export const getCancerPrediction = async (body: any) => {
  console.log(body);
  const response = await api.post(`/v1/pd/stomach`, body);
  console.log("리스폰", response.data);
  return response.data;
};
