import { useState, ReactNode } from "react";
import { getCancerPrediction } from "./api/LocationApi";
import { useForm } from "react-hook-form";
import * as S from "./style.calculator";

export interface PredictJsonDataType {
  [key: string]: string | null;
  age: string | null;
  alcohol: string | null;
  blds: string | null;
  bmi: string | null;
  bpHigh: string | null;
  cci: string | null;
  gammaGtp: string | null;
  income: string | null;
  physicalActivity: string | null;
  sgptAlt: string | null;
  smoking: string | null;
  totChole: string | null;
}

export interface PredictDataType {
  [key: string]: string | null | number | object;
  age: number | null;
  alcohol: AlcoholType | null;
  blds: number | null;
  bmi: number | null;
  bpHigh: number | null;
  cci: CCIType | null;
  gammaGtp: number | null;
  income: IncomeType | null;
  physicalActivity: PAType | null;
  sgptAlt: number | null;
  smoking: SmokingType | null;
  totChole: number | null;
}

export interface PredictResultType {
  prediction: number;
  responseInput: PredictDataType;
}

export interface AlcoholType {
  md: number;
  hg: number;
}

export interface CCIType {
  cci: number;
  cci2: number;
  cci3: number;
}

export interface IncomeType {
  low: number;
  md: number;
  hg: number;
  vh: number;
}

export interface SmokingType {
  ex: number;
  cu: number;
}

export interface PAType {
  md: number;
  hg: number;
}

const Calculator = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PredictJsonDataType>({
    defaultValues: {
      sex: "male",
    },
  });

  const [data, setData] = useState<PredictResultType>();

  const onSubmit = async (data: any) => {
    const parsedData: { [key: string]: string | object | null | number } = {};
    Object.keys(data).map((key) => {
      try {
        parsedData[key] = JSON.parse(data[key] as string);
      } catch (error) {
        parsedData[key] = data[key];
      }
    });

    const response = await getCancerPrediction(parsedData as PredictDataType);
    if (response.data) {
      setData(response.data);
    } else {
      alert("결과를 가져올 수 없습니다.");
    }
  };

  const inputList = [
    {
      title: "만 나이",
      id: "age",
      unit: "세",
    },
    {
      title: "bmi",
      id: "bmi",
    },
    {
      title: "수축기 혈압",
      id: "bpHigh",
      unit: "mmHg",
    },
    {
      title: "공복혈당",
      id: "blds",
      unit: "mg/dl",
    },
    {
      title: "ALT",
      id: "sgptAlt",
    },
    {
      title: "감마 지티피",
      id: "gammaGtp",
    },
    {
      title: "총 콜레스테롤",
      id: "totChole",
    },
  ];

  const sexList = [
    {
      title: "sex",
      title_kr: "성별",
      question: "당신의 성별은 무엇인가요?",
      option: [
        {
          title: "남성",
          value: "male",
        },
        {
          title: "여성",
          value: "female",
        },
      ],
    },
  ];

  const selectList = [
    {
      title: "smoking",
      title_kr: "흡연",
      question: "담배를 얼마나 자주 피우시나요?",
      option: [
        {
          type: "smoking",
          title: "아예 피우지 않습니다.",
          value: { ex: 0, cu: 0 },
        },
        {
          type: "smoking",
          title: "과거에 흡연을 했으나 현재는 아닙니다.",
          value: { ex: 1, cu: 0 },
        },
        {
          type: "smoking",
          title: "현재 흡연을 하고 있습니다.",
          value: { ex: 0, cu: 1 },
        },
      ],
    },
    {
      title: "alcohol",
      title_kr: "음주",
      question: "음주를 얼마나 자주 하시나요?",
      option: [
        {
          type: "alcohol",
          title: "거의 마시지 않습니다. (월 2-3회)",
          value: { md: 0, hg: 0 },
        },
        {
          type: "alcohol",
          title: "가끔 마십니다. (주 1-3회)",
          value: { md: 1, hg: 0 },
        },
        {
          type: "alcohol",
          title: "자주 마십니다. (주 4회 이상)",
          value: { md: 0, hg: 1 },
        },
      ],
    },
    {
      title: "physicalActivity",
      title_kr: "운동",
      question: "운동을 얼마나 자주 하시나요?",
      option: [
        {
          type: "physicalActivity",
          title: "거의 하지 않습니다. (주 1-2회 미만)",
          value: { md: 0, hg: 0 },
        },
        {
          type: "physicalActivity",
          title: "적당히 하고 있습니다. (주 3-4회)",
          value: { md: 1, hg: 0 },
        },
        {
          type: "physicalActivity",
          title: "자주 하고 있습니다. (주 5회 이상)",
          value: { md: 0, hg: 1 },
        },
      ],
    },
    {
      title: "income",
      title_kr: "소득",
      question: "평균 소득이 얼마인가요?",
      option: [
        {
          type: "income",
          title: "매우낮음",
          value: { low: 0, md: 0, hg: 0, vh: 0 },
        },
        {
          type: "income",
          title: "낮음",
          value: { low: 1, md: 0, hg: 0, vh: 0 },
        },
        {
          type: "income",
          title: "보통",
          value: { low: 0, md: 1, hg: 0, vh: 0 },
        },
        {
          type: "income",
          title: "높음",
          value: { low: 0, md: 0, hg: 1, vh: 0 },
        },
        {
          type: "income",
          title: "매우높음",
          value: { low: 0, md: 0, hg: 0, vh: 1 },
        },
      ],
    },
    {
      title: "cci",
      title_kr: "CCI",
      question: "CCI는 어떻게 되나요?",
      option: [
        {
          type: "cci",
          title: "질병없음",
          value: {
            cci1: 0,
            cci2: 0,
            cci3: 0,
          },
        },
        {
          type: "cci",
          title: "경도에서 중등도의 기저질병",
          value: {
            cci1: 1,
            cci2: 0,
            cci3: 0,
          },
        },
        {
          type: "cci",
          title: "중등도의 심각한 기저질병",
          value: {
            cci1: 0,
            cci2: 1,
            cci3: 0,
          },
        },
        {
          type: "cci",
          title: "중등도의 심각한 기저질병이 여러개",
          value: {
            cci1: 0,
            cci2: 0,
            cci3: 1,
          },
        },
      ],
    },
  ];
  return (
    <S.Container>
      <S.PredictContainer>
        <S.PredictTitle>
          <S.PredictTitleP>위암,</S.PredictTitleP>
          <p>
            5년예측 <S.DeathRate>사망률</S.DeathRate>은?
          </p>
        </S.PredictTitle>
        <S.SubTitleContainer>
          <S.Hr />
          <S.SubTitle>5년예측 사망률 계산기</S.SubTitle>
          <S.Hr />
        </S.SubTitleContainer>
        <S.PredictWrapper>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            {sexList.map((item, index) => (
              <S.FieldContainer key={index}>
                <S.Title>{`${index + 1}. ${item.question}`}</S.Title>
                <S.RadioList key={index}>
                  {item.option.map((v, i) => (
                    <div key={i}>
                      <S.RadioInput
                        {...register("sex", {
                          required: `${v.title} 은 필수 선택 항목입니다.`,
                        })}
                        type="radio"
                        value={v.value}
                        id={v.title.replace(/\s+/g, "-").toLowerCase()}
                      />
                      <S.RadioLabel
                        htmlFor={v.title.replace(/\s+/g, "-").toLowerCase()}
                      >
                        {v.title}
                      </S.RadioLabel>
                      <S.ErrorMsg>
                        {errors[v.value]?.message as ReactNode}
                      </S.ErrorMsg>
                    </div>
                  ))}
                </S.RadioList>
              </S.FieldContainer>
            ))}
            {inputList.map((v, index) => {
              return (
                <S.FieldContainer key={v.id}>
                  <S.Title htmlFor={v.id}>{`${index + 2}. ${v.title}`}</S.Title>
                  <S.Input
                    type="number"
                    id={v.id}
                    {...register(v.id, {
                      required: `⚠️ ${v.title}(은)는 필수 입력 항목입니다.`,
                      maxLength: 5,
                    })}
                  />
                  <S.Error>
                    <S.ErrorMsg>
                      {errors[v.id]?.message as ReactNode}
                    </S.ErrorMsg>
                  </S.Error>
                </S.FieldContainer>
              );
            })}
            <div>
              {selectList.map((item, index) => (
                <S.FieldContainer key={index}>
                  <S.Title>{`${index + 9}. ${item.question}`}</S.Title>
                  <S.RadioList key={index}>
                    {item.option.map((v, i) => (
                      <S.RadioInputContainer key={i}>
                        <S.RadioInput
                          {...register(`${v.type}`, {
                            required: `${v.title} 은 필수 선택 항목입니다.`,
                          })}
                          type="radio"
                          value={JSON.stringify(v.value)}
                          id={v.title.replace(/\s+/g, "-").toLowerCase()}
                        />
                        <S.RadioLabel
                          htmlFor={v.title.replace(/\s+/g, "-").toLowerCase()}
                        >
                          {v.title}
                        </S.RadioLabel>
                        <S.Error>
                          <S.ErrorMsg>
                            {errors[v.title]?.message as ReactNode}
                          </S.ErrorMsg>
                        </S.Error>
                      </S.RadioInputContainer>
                    ))}
                  </S.RadioList>
                </S.FieldContainer>
              ))}
            </div>
            <S.BtnContainer>
              <S.ResultBtn>결과 확인</S.ResultBtn>
            </S.BtnContainer>
            {/* <DevTool control={control} /> */}
          </form>
          {data && (
            <S.Result>
              당신의 5년 예측 위암 사망률은 {(data.prediction * 100).toFixed(2)}
              % 입니다.
            </S.Result>
          )}
        </S.PredictWrapper>
      </S.PredictContainer>
    </S.Container>
  );
};

export default Calculator;
