import { createGlobalStyle } from "styled-components";
import { reset } from "styled-reset";

const GlobalStyle = createGlobalStyle`
 ${reset}
@font-face {
    font-family: 'Pretendard-Regular';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-style: normal;
}

* {
    text-decoration: none;
    list-style: none;
}

body {
    font-family: 'Pretendard-Regular';
}

// checkbox

// 기본스타일을 없애고, 버튼모양을 구현.
input[type='checkbox'] {
  -webkit-appearance: none; // 웹킷 브라우저에서 기본 스타일 제거
  -moz-appearance: none; // 모질라 브라우저에서 기본 스타일 제거 
  appearance: none; // 기본 브라우저에서 기본 스타일 제거
  width: 18px;
  height: 18px;
  border: 2px solid #ccc; // 체크되지 않았을 때의 테두리 색상
  border-radius: 50%;
  outline: none; // focus 시에 나타나는 기본 스타일 제거
  cursor: pointer;
}

// 체크될 시에, 변화되는 스타일 설정
input[type='checkbox']:checked {
  background-color:  #4cbeb3; // 체크 시 내부 원으로 표시될 색상
  border: 3px solid white; // 테두리가 아닌, 테두리와 원 사이의 색상
  box-shadow: 0 0 0 1.6px  #4cbeb3; // 얘가 테두리가 됨
  // 그림자로 테두리를 직접 만들어야 함 (퍼지는 정도를 0으로 주면 테두리처럼 보입니다.)
  // 그림자가 없으면 그냥 설정한 색상이 꽉 찬 원으로만 나옵니다.
}

input[type='checkbox'] {
  transition: border 0.2s ease-in-out;
}

// radio button
// 기본스타일을 없애고, 버튼모양을 구현.
input[type='radio'] {
  -webkit-appearance: none; // 웹킷 브라우저에서 기본 스타일 제거
  -moz-appearance: none; // 모질라 브라우저에서 기본 스타일 제거 
  appearance: none; // 기본 브라우저에서 기본 스타일 제거
  width: 18px;
  height: 18px;
  border: 2px solid #ccc; // 체크되지 않았을 때의 테두리 색상
  border-radius: 50%;
  outline: none; // focus 시에 나타나는 기본 스타일 제거
  cursor: pointer;
}

// 체크될 시에, 변화되는 스타일 설정
input[type='radio']:checked {
  background-color:  #4cbeb3; // 체크 시 내부 원으로 표시될 색상
  border: 3px solid white; // 테두리가 아닌, 테두리와 원 사이의 색상
  box-shadow: 0 0 0 1.6px  #4cbeb3; // 얘가 테두리가 됨
  // 그림자로 테두리를 직접 만들어야 함 (퍼지는 정도를 0으로 주면 테두리처럼 보입니다.)
  // 그림자가 없으면 그냥 설정한 색상이 꽉 찬 원으로만 나옵니다.
}

input[type='radio'] {
  transition: border 0.2s ease-in-out;
}

`;

export default GlobalStyle;
